section {
  padding: 80px 0;
  @media (max-width: 767px) {
    padding: 60px 0;
  }
  & > div {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 24px;
  }
  &.no-padding {
    padding: 0;
  }
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-between {
  justify-content: space-between;
}
