.footer {
  background-color: #21325b;
  color: #fff;
  min-height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  a {
    text-decoration: none;
    color: #377dff;
    &:hover {
      color: #fff;
    }
  }
}
