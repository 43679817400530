body {
  font-family: "Poppins", sans-serif;
  color: #1d2d35;
  font-size: 16px;
  background-color: #ffffff;
}
.App {
  text-align: center;
}

.secondary-text {
  color: #ffffff;
}

.primary-text {
  color: #1b1642;
}
.secondary-text {
  color: #1b1642;
  font-size: 20px;
  font-weight: normal;
}
h2 {
  font-size: 44px;
  margin: 0 0 14px 0;
  @media (max-width: 767px) {
    font-size: 30px;
  }
}
h3 {
  font-size: 24px;
}
h3,
h4 {
  margin: 0;
}
.mb-60 {
  margin-bottom: 60px;
}
.overlay-bg {
  background-color: #21325b;
  min-height: 300px;
  color: #fff;
  position: relative;
  padding: 40px 0;
  .position-absolute {
    position: absolute;
    left: 0;
    bottom: -5px;
    height: 25px;
    width: 100%;
    .overflow-hidden {
      overflow: hidden;
      svg {
        -webkit-transform: scale(2);
        transform: scale(2);
        width: 100%;
        height: auto;
        -webkit-transform-origin: top center;
        transform-origin: top center;
      }
    }
  }
  @media (max-width: 767px) {
    min-height: 20px;
    padding-bottom: 100px;
  }
  h1 {
    font-size: 54px;
    color: #fff;
    margin: 0;
    @media (max-width: 767px) {
      font-size: 30px;
      margin: 0 0 15px 0;
    }
  }
  p {
    font-size: 22px;
    margin: 0;
    color: #fff;
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
}

.slanted-background {
  height: 30px;
  margin: 0;
  background: #ffffff;
}

.slanted-background:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 30%;
  background: #21325b;
  transform: skew(-30deg);
  transform-origin: top;
}

.slanted-background-right {
  height: 30px;
  margin: 0;
  background: #ffffff;
}

.slanted-background-right:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  background: #21325b;
  transform: skew(-150deg);
  transform-origin: top;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.unmatched-performance {
  background-color: #fafafa;
}
.language {
  button {
    max-width: 288px;
    text-align: left;
    text-transform: capitalize;
    border-radius: 4px;
    opacity: 1;
    background-color: #fff;
    transition: background-color 0.3s ease-in-out;
    margin-bottom: 10px;
    min-height: 44px;
    @media (max-width: 767px) {
      min-width: 29.33%;
      text-align: center;
      padding: 6px 20px;
    }
    &.Mui-selected,
    &:hover {
      background-color: #21325b;
      span {
        transition: color 0.3s ease-in-out;
        color: #fff;
      }
    }
    span {
      align-items: flex-start;
      color: #377dff;
      text-transform: none;
      @media (max-width: 767px) {
        align-items: center;
      }
    }
  }
  .MuiTabs-flexContainer {
    & ~ span {
      display: none;
    }
  }
  .MuiTabs-flexContainerVertical {
    @media (max-width: 767px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}
body ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}
body ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
::selection {
  background-color: #cce2ff;
  color: rgba(0, 0, 0, 0.87);
}
