.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  flex-direction: column;
}
.wrapper {
  width: 620px;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.13);
  position: relative;
  top: -110px;
  @media (max-width: 767px) {
    width: 94%;
    top: -60px;
  }
}

.formContainer {
  width: 620px;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.13);
  border: 1px solid rgba(0, 0, 0, 0.13);
  position: relative;
  top: -85px;
  @media (max-width: 767px) {
    width: 94%;
    top: -40px;
  }
}

.termHeader {
  padding: 0 16px;
  background-color: #efefef;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0 0;
  img {
    height: 12px;
  }
}
.termBox {
  padding-right: 5px;
}

.terminalBlock {
  text-align: left;
  padding: 24px;
  background-color: rgb(255, 255, 255);
  color: rgb(93, 93, 93);
  width: 620px;
  min-height: 340px;
  overflow-y: auto;
  word-break: break-all;
  border-radius: 0 0 5px 5px;
  @media (max-width: 767px) {
    width: 100%;
  }
  pre {
    margin: 0;
    line-height: 24px;
    overflow: auto;
    height: 250px;
  }
}

.emailBox {
  background: #fff;
  border: 1px solid #dbdbdb;
  padding: 0.2rem;
  padding-bottom: 0.5rem;
  position: absolute;
  left: 2px;
  right: 2px;
  bottom: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  height: 30px;
}

.inputStyle {
  display: inline;
}

.emailInput {
  border: none;
  border-radius: 3px;
  padding: 5px;
  width: 100%;
  color: #1d2d35;
  top: 10px;
  position: relative;
}

.emailInput:focus {
  outline: none;
}

.emailBoxButton {
  background-color: #21325b;
  color: #fff;
}

pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.formStyle {
  // width: 90%;
  padding: 10px;
  position: relative;
  top: -10px;
}
