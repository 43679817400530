.container {
  background-color: #ffffff;
  padding: 20px 0;
}

.box {
  display: flex;
  max-width: 980px;
  justify-content: center;
  align-items: top;
  margin: 0 auto;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.13);
  background: #21325b;
  border-radius: 5px;
  max-height: 400px;
  @media (max-width: 768px) {
    max-width: 94%;
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.tabs {
  border-right: 1px solid transparent;
  width: 288px;
  background-color: #fff;
  padding: 0 20px;
  @media (max-width: 767px) {
    width: 100%;
  }
}
.tabpanel {
  text-align: left;
  width: 100%;
  height: 400px;
  overflow: auto;
  padding: 0;
  & > div {
    padding:20px;
    height: 100%;
    pre {
      margin: 0 !important;
      background-color: transparent !important;
      border: 0 !important;
      box-shadow: none !important;
      padding: 0 0 1em 0 !important;
      word-break: break-all !important;
      height: 100%;
      // border-radius: none !important;
      code {
        word-break: break-all !important;
        white-space: break-spaces !important;
        &:focus {
          text-shadow:none !important;
        }
        &::selection {
          text-shadow:none !important;
          background-color: rgba(0, 0, 1, 0.24);
          color: inherit;
        }
        span {
          &::selection {
            text-shadow:none !important;
            background-color:rgba(0, 0, 1, 0.24);
            color: inherit;

          // color: #ffffff;
          }
        }
      }
    }
  }
}
.tabContainer {
  display: flex;
  justify-content: center;
  width: calc(100% - 288px);
  @media (max-width: 767px) {
    width: 100%;
  }
}
.tabHeader {
  display: flex;
  justify-content: flex-end;
  height: 48;
  width: 100%;
}
