.nav {
  display: flex;
  align-items: center;
  img {
    width: 162px;
  }
}

.Logotext {
  font-size: 30px;
  color: #21325b;
  font-weight: 700;
}

.Beta {
  background-color: #F9917A;
  color: #fff;
  font-size: 10px;
  width: 18px;
  font-weight: 400;
  padding: 2px 5px;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}