.flexContainer {
  display: flex;
  justify-content: space-around;
  align-items: top;
  flex-wrap: wrap;
  margin-top: 60px;
  .flexBox {
    width: calc(33.33% - 24px);
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 60px;
    }
    &:not(:last-child) {
      margin-right: 24px;
      @media (max-width: 767px) {
        margin-right: 0;
      }
    }
    img {
      width: 76px;
      margin-bottom: 24px;
      min-height: 76px;
      @media (max-width: 767px) {
        width: 120px;
      }
    }
  }
}
